.metismenu li.active>a {
    color: #2185d0
}

.react-datepicker-wrapper {
    display: block !important;
}

.card-remove {
    display: none;
}


/* .circleCenter{margin-left: 90px;} */

.dLang {
    position: absolute;
    will-change: transform;
    transform: translate3d(-156px, 32px, 0px);
    top: 0;
    left: 0;
}
.dMail {
    position: absolute;
    will-change: transform;
    transform: translate3d(-312px, 32px, 0px);
    top: 0;
    left: 0;
}
.dNoti {
    position: absolute;
    will-change: transform;
    transform: translate3d(-282px, 32px, 0px);
    top: 0;
    left: 0;
}
.dProfile {
    position: absolute;
    will-change: transform;
    transform: translate3d(-77px, 32px, 0px);
    top: 0;
    left: 0;
}
.d3Dot {
    position: absolute; 
    will-change: transform; 
    top: 0px; 
    left: 0px; 
    transform: translate3d(-174px, -292px, 0px);
}
.dActivity {
    position: absolute; 
    will-change: transform; 
    top: 0px;
    left: 0px; 
    transform: translate3d(-174px, 25px, 0px);
}

.text-error{
    font-size: 80%;
}

.form-control.is-invalid-select, .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.swal2-title{
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel {
    font-size: 1em !important;
}